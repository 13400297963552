export const INFO_STEP = 'info';
export const DOCUMENTS_STEP = 'documents';

export const TEST_STEP = 'test';

export const DISBURSEMENT_STEP = 'disbursement';

export const FIRST_STEP = 'firstStep';

export const DASHBOARD = 'stepOneFinished';
export const REGISTER = 'register';

export const StepsMapping = {
    [REGISTER]: 'register',
    [FIRST_STEP]: 'firstStep',
    [DASHBOARD]: 'stepOneFinished',
    [INFO_STEP]: 'stepTwoFinished',
    [TEST_STEP]: 'stepThreeFinished',
    [DOCUMENTS_STEP]: 'stepFourFinished',
    [DISBURSEMENT_STEP]: 'terminated',
}

export const StepsFlow = [
    INFO_STEP,
    // TEST_STEP,
    DOCUMENTS_STEP,
    DISBURSEMENT_STEP,
]


export const getStepIndex = (step) => {
    let activeStep = step;

    switch (step) {
        case 'stepTwoFinished':
        case 'stepThreeFinished':
            activeStep = 'info';
            break;
        // case 'preApproved':
        //     activeStep = 'stepOneFinished';
        //     break;
        case 'stepFourFinished':
        case 'terminated':
            activeStep = 'upload';
            break;
    }

    // console.log('step', step)
    // console.log('activeStep', activeStep)

    return activeStep;
}

