import React, { useMemo } from "react";
import { currencyFormatter } from "../../../helpers/currencyFormat";

const Calculations = ({
  apr,
  maxAPR,
  effectiveTerm,
  averageMonthlyPayment,
  totalCosts,
}) => {
  const APR = useMemo(() => {
    let val = (apr * 100 <= 12.9 ? apr * 100 : maxAPR * 100).toFixed(2); // 12.9 is maximum apr
    return val < 0 ? "-" : `${val}%`;
  }, [apr, maxAPR]);

  return (
    <>
      <div className="half">
        <ul>
          <li>
            <span>Total Costs</span>
            <span>
              {totalCosts < 0
                ? currencyFormatter(0)
                : currencyFormatter(totalCosts)}
            </span>
          </li>
          <li>
            <span>Effective Term</span>
            <span>{effectiveTerm} months</span>
          </li>
          <li>
            <span>Average Monthly Payment</span>
            <span>{currencyFormatter(averageMonthlyPayment, 2)}</span>
          </li>
          <li>
            <span>APR</span>
            <span>{APR}</span>
          </li>
        </ul>
      </div>

      <div className="full" style={{ marginTop: "2.4rem" }}>
        <small>
          Assumes that earned income remains constant. Total Costs are in 
          addition to the funding amount received. Assumes YELO withholds from 
          salary at a rate of 0.75% for every thousand funded. Does not assume
          any payment pauses. ICF terms: 3% Disbursement Fee; 6-year max; 
          Repayment cap of 1.90x funding amount. Minimum Income Threshold:
          $30,000. Payments start 12 months after In-School Period and 3 months 
          after Grace Period. You can refer to the United States Department of 
          Education College Scorecard for salary estimates related to your field
          of study at{" "}
          <a
            href="https://collegescorecard.ed.gov"
            title="https://collegescorecard.ed.gov"
            target="_blank"
            rel="noreferrer"
          >
            https://collegescorecard.ed.gov
          </a>
          .
        </small>
      </div>
    </>
  );
};

export default Calculations;
