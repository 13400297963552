import React from "react";
import { Box, Typography } from "@mui/material";
import awardFundingLogo from "../../../assets/images/funding_award.jpg";

const Award = () => {
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "calc(100% - 30px)", sm: "calc(100% - 90px)" },
        maxWidth: "1260px",
        margin: "0 auto",
        textAlign: "center",
        padding: { xs: "20px 0", md: "0" },
        position: "relative",
      }}
    >
      <Box
        display={'flex'}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: {xs: "100%", md: "30%"},
            display: "flex",
            justifyContent: "center",
            marginBottom: { xs: "2rem", md: 0 },
            alignItems: "center",
          }}
        >
          <a href="https://www.educationinsidermagazine.com/yelo-funding" target="_blank" rel="noreferrer">
            <img
              src={awardFundingLogo}
              alt="Award Logo"
              style={{
                maxWidth: "100%",
                height: '100%',
                borderRadius: "8px",
              }}
            />
          </a>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: {xs: "100%", md: "55%"},
            textAlign: { xs: "center", md: "left" },
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: "1.6rem", md: "1.75rem" },
              color: "#203864",
              lineHeight: "1.6",
              fontFamily: "Halcom W00 Regular,League Spartan,sans-serif !important"
            }}
          >
            <strong>YELO Funding</strong> has been honored as the <strong>Student Funding
            Company of the Year 2025</strong>, a testament to its stellar
            reputation and the trust it has earned among customers
            and industry peers. This recognition reflects numerous
            nominations from Education Insider subscribers and a
            thorough evaluation by a distinguished panel of C-level
            executives, industry thought leaders, and the editorial board.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Award;
